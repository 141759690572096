













































import { useGetters } from '@u3u/vue-hooks'
import { defineComponent, PropType, ref } from '@vue/composition-api'

import { SelectOption } from '@/inc/types'

import FormDropdown from '@/components/form/Dropdown.vue'

export default defineComponent({
  name: 'ProjectCategories',
  components: {
    FormDropdown,
  },
  props: {
    currentCategory: {
      type: Object as PropType<SelectOption[]>,
      required: false,
    },
    currentSubcategory: {
      type: Object as PropType<SelectOption[]>,
      required: false,
    },
  },

  setup(_props, ctx) {
    const el = ref<HTMLElement | null>(null)
    const isSticky = ref(false)
    const navHeight = 60
    let distanceToTop: number

    const onScroll = (x: number, y: number) => {
      // Round value because of iOS bug that adds/removes .25px to the value
      const newdistanceToTop = Math.round(el.value!.getBoundingClientRect().top)
      const paddingTop = window.innerWidth < 1024 ? 0 : 40

      if (distanceToTop === newdistanceToTop) {
        return
      }

      distanceToTop = newdistanceToTop + y

      isSticky.value = y + navHeight === distanceToTop + paddingTop
      document.body.classList[isSticky.value ? 'add' : 'remove'](
        'sticky-dropdown'
      )
    }

    const onInput = (value: string, isSubcategory = false) => {
      if (isSubcategory) {
        ctx.emit('subcategory:change', value)
      } else {
        ctx.emit('category:change', value)
      }
    }

    return {
      ...useGetters(['content']),
      el,
      isSticky,
      onScroll,
      onInput,
    }
  },
})
