






































































import {
  defineComponent,
  PropType,
  ref,
  computed,
  onMounted,
} from '@vue/composition-api'

import { SelectOption } from '@/inc/types'

import styles from './dropdown.module.scss'

type CustomStyle = Record<string, string>

export default defineComponent({
  name: 'FormDropdown',
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<SelectOption[]>,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    // REVIEW: may be some visually-hidden ?
    showLabel: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    classes: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    css: {
      type: Object as PropType<CustomStyle>,
      default: () => ({} as Record<string, never>),
    },
  },

  setup(props, ctx) {
    // Checks
    const hasCustomTrigger = ctx.slots.trigger !== undefined
    const hasCustomOption = ctx.slots.option !== undefined
    let hasInit = false
    let canToggle = false

    const rootEl = ref<HTMLElement>()
    const triggerEl = ref<HTMLElement>()
    const listEl = ref<HTMLElement>()
    let blurTimeout: ReturnType<typeof setTimeout>
    let searchTimeout: ReturnType<typeof setTimeout>

    // Statuses
    const isExpanded = ref(false)
    const hasSelection = ref(props.value !== undefined)

    // Values
    const selectedIndex = ref(
      props.value ? props.options.findIndex(o => props.value === o.value) : 0
    )
    const activeIndex = ref<number>(selectedIndex.value)
    const selected = computed(() => props.options[selectedIndex.value])
    const active = computed(() => props.options[activeIndex.value])
    const triggerLabel = computed(() =>
      hasSelection.value ? selected.value.label : props.placeholder
    )

    // Search needle
    let needle = ''

    // Custom styles
    const customStyles = computed(() =>
      Object.keys(styles).reduce((acc: CustomStyle, prop: string) => {
        const base: string = styles[prop]
        const extra = Object.keys(props.css).find(name => name === prop)

        acc[prop] = extra ? `${props.css[extra]} ${base}` : base

        return acc
      }, {})
    )

    const open = () => {
      activeIndex.value = selectedIndex.value
      isExpanded.value = true
      ctx.emit('open')
    }

    const close = () => {
      isExpanded.value = false
      ctx.emit('close')
    }

    const toggle = () => {
      if (isExpanded.value) {
        // Do not close on focus triggered by first button click
        canToggle && close()
      } else {
        open()
      }
    }

    const init = () => {
      !hasInit && document.addEventListener('keydown', onKeydown)
      hasInit = true
      open()
      canToggle = false
      setTimeout(() => {
        canToggle = true
      }, 250)
    }

    const destroy = () => {
      document.removeEventListener('keydown', onKeydown)
      hasInit = false
      close()
      triggerEl.value!.blur()
    }

    const select = () => {
      selectedIndex.value = activeIndex.value
      hasSelection.value = true
      triggerEl.value!.focus()
      ctx.emit('input', selected.value.value)
    }

    const search = (input: string) => {
      const cleanString = (str: string) =>
        str
          .toLowerCase()
          .normalize('NFD')
          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
      // Normalize input
      needle += cleanString(input)

      const result = props.options.findIndex(o =>
        cleanString(o.label).startsWith(needle)
      )

      if (result > -1) {
        activeIndex.value = result
      }

      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(() => {
        needle = ''
      }, 300)
    }

    const scrollToSelected = () => {
      setTimeout(() => {
        const el = rootEl.value!.querySelector(
          '.option[aria-selected]'
        ) as HTMLElement

        listEl.value!.scroll({
          top: el!.offsetTop,
          left: 0,
          behavior: 'smooth',
        })
      }, 150)
    }

    const onKeydown = (event: KeyboardEvent) => {
      if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
        return
      }

      // Prevent page scroll
      event.preventDefault()

      switch (event.key) {
        // Move selection
        case 'ArrowUp':
          !isExpanded.value && open()
          activeIndex.value = Math.max(activeIndex.value - 1, 0)
          scrollToSelected()
          break
        case 'ArrowDown':
          !isExpanded.value && open()
          activeIndex.value = Math.min(
            activeIndex.value + 1,
            props.options.length - 1
          )
          scrollToSelected()
          break
        case 'Home':
          !isExpanded.value && open()
          activeIndex.value = 0
          scrollToSelected()
          break
        case 'End':
          !isExpanded.value && open()
          activeIndex.value = props.options.length - 1
          scrollToSelected()
          break
        // Close and leave
        case 'Tab':
          destroy()
          break
        // Close and stay
        case 'Escape':
          close()
          break
        // Select and toggle
        case 'Enter':
        case ' ':
          isExpanded.value && select()
          toggle()
          break
        // Search
        default:
          // Quick filter (to avoid f5, Ctrl, …)…
          if (event.key.length === 1) {
            !isExpanded.value && open()
            search(event.key)
          }
      }
    }

    const onSelect = (index: number) => {
      // Prevent blur callback
      clearTimeout(blurTimeout)
      activeIndex.value = index
      select()
      close()
    }

    const onClick = () => {
      toggle()
    }

    onMounted(() => {
      triggerEl.value!.addEventListener('focus', init)
      triggerEl.value!.addEventListener('blur', () => {
        // This timeout allows blur+click combo aka option click
        blurTimeout = setTimeout(() => {
          isExpanded.value && destroy()
        }, 150)
      })
    })

    return {
      hasCustomTrigger,
      hasCustomOption,
      active,
      selected,
      rootEl,
      triggerEl,
      listEl,
      triggerLabel,
      isExpanded,
      customStyles,
      onSelect,
      onClick,
    }
  },
})
