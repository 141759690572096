


























import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import gsap from 'gsap'
import SplitText from 'gsap/dist/SplitText.js'

import { push } from '@/inc/utils'

gsap.registerPlugin(SplitText)

export default defineComponent({
  name: 'ProjectArchive',

  setup() {
    const headingHeight = ref(0)
    const trackingParams = {
      /* eslint-disable camelcase, id-length */
      realisation_seen: 0,
      realisation_clicked: 0,
      realisation_category_change: 0,
      realisation_subcategory_change: 0,
      realisation_load_more: 0,
      realisation_read_more: 0,
      /* eslint-enable camelcase, id-length */
    }

    const enter = (el: HTMLElement, done) => {
      const heading = el.querySelector('.project-archive__heading')
      const title = el.querySelector('.title')
      const intro = el.querySelectorAll('.intro p')
      const highlighted = el.querySelector('.project-archive__highlighted')
      const projects = el.querySelectorAll('.masonry-grid__item')
      const loadmore = el.querySelector('.project-archive__grid__loadmore')
      const splitTitle = new SplitText(title, { type: ['words'] })
      const tl = gsap.timeline({
        onComplete: () => {
          gsap.set([heading, intro], { clearProps: 'all' })
          gsap.set(splitTitle.words, { clearProps: 'clipPath' })
          done()
          headingHeight.value = heading!.clientHeight
        },
      })

      tl.set(splitTitle.words, {
        clipPath: 'inset(0% 0% 100% 0%)',
      })
      tl.add('start', 0)

      // smoothly place filters
      if (headingHeight.value !== 0) {
        tl.from(heading, {
          height: headingHeight.value,
        })
      }

      tl.to(
        splitTitle.words,
        {
          clipPath: 'inset(0% 0% -30% 0%)',
          stagger: 0.05,
          duration: 0.75,
          ease: 'power4.out',
        },
        'start'
      )
      tl.from(
        splitTitle.words,
        {
          y: 50,
          stagger: 0.05,
          duration: 0.5,
          ease: 'power4.out',
        },
        'start'
      )
      tl.from(
        intro,
        {
          opacity: 0,
          duration: 1.5,
          ease: 'power4.out',
        },
        'start+=0.2'
      )

      tl.from(projects, { y: 50, opacity: 0, stagger: 0.1 }, 'start+=0.3')

      if (highlighted) {
        tl.from(highlighted, { y: 50, opacity: 0, stagger: 0.1 }, 'start+=0.3')
      }

      if (loadmore) {
        tl.from(loadmore, { opacity: 0, duration: 0.25 }, 'start+=0.3')
      }
    }

    const leave = (el: HTMLElement, done) => {
      const title = el.querySelector('.title')
      const highlighted = el.querySelector('.project-archive__highlighted')
      const grid = el.querySelector('.masonry-grid')
      const intro = el.querySelector('.intro')
      const loadmore = el.querySelector('.project-archive__grid__loadmore')
      const splitTitle = new SplitText(title, { type: 'words' })

      const tl = gsap.timeline({
        onComplete: () => {
          done()
        },
      })

      if (loadmore) {
        tl.to(loadmore, { opacity: 0, duration: 0.1 })
      }

      if (highlighted) {
        tl.to(highlighted, { y: 50, opacity: 0, duration: 0.25 })
      }

      tl.to(grid, { y: 50, opacity: 0, stagger: 0.1 })

      tl.to(
        intro,
        {
          opacity: 0,
          duration: 0.5,
          ease: 'power4.out',
        },
        0
      )
      tl.set(
        splitTitle.words,
        {
          clipPath: 'inset(0% 0% -30% 0%)',
        },
        0
      )
      tl.to(
        splitTitle.words,
        {
          clipPath: 'inset(0% 0% 100% 0%)',
          stagger: -0.05,
          duration: 0.75,
          ease: 'power4.out',
        },
        0
      )
      tl.to(
        splitTitle.words,
        {
          y: 50,
          stagger: -0.05,
          duration: 0.5,
          ease: 'power4.out',
        },
        0
      )
    }

    // Keep track of relevant events
    const track = (property: string) => {
      trackingParams[property] += 1
    }

    // Push event with tracking params
    const pushDatalayer = () => {
      // Summary event: all params of the session
      push({
        event: 'realisation_summary',
        ...trackingParams,
      })

      // Impression event: number of viewed cards during the session
      push({
        event: 'realisation_impressions',
        realisation_number: trackingParams.realisation_seen, // eslint-disable-line camelcase
      })
    }

    onMounted(() => {
      // Push datalayer before the window is closed
      window.addEventListener('beforeunload', () => pushDatalayer())
    })

    onUnmounted(() => {
      // Push datalayer when leaving the page
      pushDatalayer()
    })

    return {
      enter,
      leave,
      track,
      ...useGetters(['content']),
    }
  },
})
