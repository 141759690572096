



































































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import { PCategory, PCard } from '@/inc/types'
import { segments, langAvailable, getApiUrl } from '@/inc/app.config'
import { cache, push, pushCta, GtmCta } from '@/inc/utils'

import MagazineCard from '@/components/magazine/Card.vue'
import ProjectCategories from '@/components/project/Categories.vue'
import ProjectContent from '@/components/project/Content.vue'
import ProjectGrid from '@/components/project/Grid.vue'

export default defineComponent({
  name: 'ProjectArchiveContent',
  components: {
    MagazineCard,
    ProjectCategories,
    ProjectContent,
    ProjectGrid,
  },
  setup(_props, ctx) {
    // General
    const { content } = useGetters(['content'])
    const { $router, $route } = ctx.root
    const langIndex = langAvailable.indexOf($route.params.lang as 'fr' | 'nl')
    const intro = ref<HTMLElement | null>(null)
    let introLinks: HTMLAnchorElement[] = []

    // Grid related
    const category = ref<PCategory | null>(null)
    const subcategory = ref<PCategory | null>(null)
    const displayCategory = ref(false)

    const trackLinks = () => {
      if (!intro.value) {
        return
      }

      if (introLinks.length) {
        introLinks.forEach(link => {
          link.removeEventListener('click', onIntroLinkClick)
        })
      }

      introLinks = [...intro.value.querySelectorAll('a')]

      introLinks.forEach(link => {
        link.addEventListener('click', onIntroLinkClick)
      })
    }

    const onCategoryChange = (value: string) => {
      if (category.value && category.value!.value === value) {
        return
      }

      category.value = content.value.categories.items.find(
        (c: PCategory) => c.value === value
      )

      if (value === 'all') {
        $router.push({
          path: `/${$route.params.lang}/${segments.realizations[langIndex]}/`,
        })
      } else {
        $router.push({
          path: `/${$route.params.lang}/${segments.realizations[langIndex]}/${value}/`,
        })
      }

      // Push event to datalayer
      push({
        event: 'category_change',
        // eslint-disable-next-line camelcase, id-length
        realisation_category_name: category.value?.label || 'all',
      })

      // Update session tracking
      ctx.emit('category')
      trackLinks()
    }

    const onSubcategoryChange = (value: string) => {
      if (
        !category.value ||
        !category.value!.children!.length ||
        (subcategory.value && subcategory.value!.value === value)
      ) {
        return
      }

      const path = `/${$route.params.lang}/${segments.realizations[langIndex]}/${category.value.value}/`

      if (value === 'all') {
        subcategory.value = null
        $router.push({ path })
      } else {
        subcategory.value = category.value!.children!.find(
          (c: PCategory) => c.value === value
        ) as PCategory

        $router.push({
          path: `${path}${value}/`,
        })
      }

      // Push event to datalayer
      push({
        event: 'sub_category_change',
        // eslint-disable-next-line camelcase, id-length
        realisation_subcategory_name: subcategory.value?.label,
      })

      // Update session tracking
      ctx.emit('subcategory')
      trackLinks()
    }

    // Track load more clicks
    const onLoadMore = () => {
      // Emit for realisation_summary
      ctx.emit('loadmore')

      push({
        event: 'load_more',
      })
    }

    // Track read more clicks
    const onReadmore = () => {
      // Emit for realisation_summary
      ctx.emit('readmore')

      push({
        event: 'read_more',
      })
    }

    // Track which projects have been seen
    const onProjectAppear = () => ctx.emit('project:seen')

    const onIntroLinkClick = (e: MouseEvent) => {
      const currentTarget = e.currentTarget as HTMLAnchorElement

      if (!currentTarget) {
        return
      }

      const layer: Partial<GtmCta> = {
        ctaLabel: currentTarget.innerHTML,
        ctaType: 'link_text',
        ctaUrl: currentTarget.href,
      }

      pushCta(layer, currentTarget)
    }

    /*
     ** Popup / overlay related
     */
    // Opens popup of the project page
    const popupContent = ref<PCard | Record<string, unknown> | null>(null)

    // Fetch project content
    const fetchProject = async (projectUrl: string) => {
      const url = `${getApiUrl()}/realizations/${projectUrl}`
      const res = await cache.fetch(ctx.root.$route.fullPath, url)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const data = res.data.content

      return data
    }

    const openPopup = async ({
      target,
      project,
    }: {
      target: HTMLElement
      project: PCard
    }) => {
      target.classList.add('is-loading')

      const content = await fetchProject(project.url.split('/')[3])

      // Open popup
      popupContent.value = { ...content, url: project.url }
      target.classList.remove('is-loading')

      // Push page_view
      const layer = {
        event: 'page_view',
        pageTitle: project.title,
        pageLocation: window.location.href,
        pagePath: project.url,
      }
      push(layer)

      // Update session tracking
      ctx.emit('project:click')
    }

    const closePopup = () => (popupContent.value = null)

    onMounted(() => {
      const {
        prefix: routePrefix,
        category: routeCategory,
        subcategory: routeSubcategory,
      } = $route.params

      category.value = content.value.categories.items.find(
        (c: PCategory) => c.value === `${routePrefix}-${routeCategory}`
      )

      if (category.value && category.value.children) {
        subcategory.value =
          (category.value.children.find(
            (c: PCategory) => c.value === routeSubcategory
          ) as PCategory) || null
      }

      displayCategory.value = true

      trackLinks()
    })

    onUnmounted(() => {
      // Remove intro links tracking
      if (introLinks.length) {
        introLinks.forEach(link => {
          link.removeEventListener('click', onIntroLinkClick)
        })
      }
    })

    return {
      content,
      intro,
      category,
      subcategory,
      displayCategory,
      popupContent,
      openPopup,
      closePopup,
      onCategoryChange,
      onSubcategoryChange,
      onLoadMore,
      onReadmore,
      onProjectAppear,
    }
  },
})
